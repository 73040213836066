import React from "react"

import {
  LazyContentEditorHelper,
  normalizeContent,
  normalizeCmsMeta,
} from "../../../CustomPageLayout"
import { editorHelperActive } from "../../../shared/env/constants"

const withData = Target => ({ data = {}, ...rest }) => {
  const allContent = normalizeContent(data.content)

  const { pictures, others } = allContent.reduce(
    (acc, cur) => {
      const {
        componentName,
        data: { identifier },
      } = cur

      if (
        componentName === `Svg` &&
        identifier &&
        (identifier.includes(`mobile`) || identifier.includes(`desktop`))
      ) {
        acc.pictures.push(cur)
      } else {
        acc.others.push(cur)
      }

      return acc
    },
    { pictures: [], others: [] }
  )

  const result = {
    content: others,
    pictures,
  }

  const cms = normalizeCmsMeta(data)

  return editorHelperActive ? (
    <LazyContentEditorHelper cms={cms}>
      <Target {...result} {...rest} />
    </LazyContentEditorHelper>
  ) : (
    <Target {...result} {...rest} />
  )
}

export default withData
