import React from "react"
import { contentPositionerCss } from "../../../shared/styles"
import withData from "./withData"
import { ContentRenderer } from "../../../CustomPageLayout"
import backgroundImage from "../../../../assets/backgrounds/cloud-page_proof-watermark.svg"
import arrowImage from "../../../../assets/backgrounds/cloud-page_proof-arrow.svg"

const rootCss = theme => [
  contentPositionerCss({ theme }),
  {
    paddingLeft: 0,
    paddingRight: 0,

    [theme.mediaQueries.desktop]: {
      paddingLeft: theme.space[8],
      paddingRight: theme.space[8],
    },
  },
]

const innerCss = theme => ({
  background: ` url(${backgroundImage}) no-repeat 20% center, linear-gradient(0deg, ${theme.colors.purple[10]}, ${theme.colors.blue[5]} 80%)`,
  display: `grid`,
  gridColumnGap: theme.space[12],
  padding: `${theme.space[11]} ${theme.space[6]} ${theme.space[15]}`,

  [theme.mediaQueries.desktop]: {
    gridTemplateColumns: `2fr 1fr 1.2fr`,
    padding: `${theme.space[9]} ${theme.space[11]}`,
    borderRadius: theme.radii[3],
  },
})

const headingCss = theme => ({
  fontSize: theme.fontSizes[6],
  marginBottom: theme.space[4],
  textAlign: `center`,

  [theme.mediaQueries.desktop]: {
    textAlign: `left`,
  },
})

const ledeCss = theme => ({
  margin: 0,
  color: theme.colors.grey[60],

  a: {
    fontWeight: theme.fontWeights.bold,
    textDecoration: `none`,
  },

  textAlign: `center`,

  [theme.mediaQueries.desktop]: {
    textAlign: `left`,
  },
})

const dataColCss = theme => ({
  display: `grid`,
  position: `relative`,
  justifyContent: `center`,
  padding: `${theme.space[5]} 0`,

  [theme.mediaQueries.desktop]: {
    justifyContent: `left`,
    padding: 0,
  },
})

const labelCss = theme => ({
  color: theme.colors.grey[50],
  fontSize: theme.fontSizes[1],
  fontWeight: theme.fontSizes.bold,
  letterSpacing: theme.letterSpacings.tracked,
  textTransform: `uppercase`,
  textAlign: `center`,

  [theme.mediaQueries.desktop]: {
    textAlign: `left`,
  },
})

const valueCss = theme => ({
  fontSize: theme.fontSizes[10],
  fontWeight: theme.fontWeights.extraBold,
  lineHeight: theme.lineHeights.solid,
  margin: `${theme.space[3]} 0`,
  position: `relative`,
  textAlign: `center`,

  b: {
    fontWeight: theme.fontWeights.extraBold,
    position: `relative`,

    "&:after": {
      content: `""`,
      display: `block`,
      position: `absolute`,
      left: 0,
      right: 0,
      height: `10px`,
      bottom: `-${theme.space[2]}`,
      background: theme.colors.blue[50],
    },
  },

  small: {
    fontSize: theme.fontSizes[7],
    fontWeight: theme.fontWeights.body,
  },

  [theme.mediaQueries.desktop]: {
    fontSize: theme.fontSizes[13],
    textAlign: `left`,
  },
})

const noteCss = theme => ({
  fontSize: theme.fontSizes[0],
  color: theme.colors.grey[50],
})

const typeCss = theme => ({
  position: `relative`,
  width: `100%`,

  span: {
    position: `absolute`,
    fontSize: theme.fontSizes[0],
    color: theme.colors.grey[50],
    fontWeight: theme.fontWeights.body,
    whiteSpace: `nowrap`,
  },

  img: {
    position: `absolute`,
    transformOrigin: `center center`,
  },
})

const codeCss = theme => [
  typeCss(theme),
  {
    img: {
      left: `110%`,
      top: `60%`,
      transform: `rotate(-120deg) `,
    },

    span: {
      left: `40%`,
      top: `120%`,
    },

    [theme.mediaQueries.desktop]: {
      img: {
        top: `50%`,
        transform: `rotate(-20deg) `,
      },

      span: {
        left: `110%`,
        top: `20%`,
      },
    },
  },
]

const dataCss = theme => [
  typeCss(theme),
  {
    img: {
      left: `30%`,
      top: `125%`,
      transform: `rotate(70deg) `,
    },

    span: {
      top: `180%`,
      left: `-70%`,
    },

    [theme.mediaQueries.desktop]: {
      img: {},

      span: {
        left: `30%`,
      },
    },
  },
]

const contextStyles = {
  Heading: headingCss,
  Markdown: ledeCss,
}

const contextProps = {
  Heading: {
    as: `h2`,
    variant: `EMPHASIZED`,
  },
}

export function WIBProof({ content }) {
  return (
    <div css={rootCss}>
      <div css={innerCss}>
        <div>
          <ContentRenderer
            content={content}
            contextProps={contextProps}
            contextStyles={contextStyles}
          />
        </div>
        <div css={dataColCss}>
          <span css={labelCss}>Pages</span>
          <span css={valueCss}>8,192</span>
          <span css={noteCss}>Blog site with 1 image per page</span>
        </div>
        <div css={dataColCss}>
          <span css={labelCss}>Build Time</span>
          <span css={valueCss}>
            <span css={dataCss}>
              <img src={arrowImage} alt="" />
              <b>5s</b>
              <span>{`DATA change (from your headless CMS)`}</span>
            </span>{" "}
            <span css={codeCss}>
              <img src={arrowImage} alt="" />
              <small>/ 17s</small>
              <span>{`CODE change (cached)`}</span>
            </span>
          </span>
        </div>
      </div>
    </div>
  )
}

export default withData(WIBProof)
