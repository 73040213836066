import React from "react"

import {
  CustomPageLayout,
  ContentItemRenderer,
  ComponentsProvider,
  baseComponents,
} from "../CustomPageLayout"

import { all as cloudComponents } from "./components"

const components = {
  ...baseComponents,
  ...cloudComponents,
}

const rootCss = theme => ({
  display: `grid`,
  gridTemplateColumns: `minmax(0, 1fr)`,
  gap: theme.space[10],
  marginTop: theme.space[10],

  [theme.mediaQueries.desktop]: {
    gap: theme.space[15],
  },
})

export function CloudPage({ seo, location, content }) {
  let featureNum = 0

  return (
    <CustomPageLayout seo={seo} location={location}>
      <ComponentsProvider value={components}>
        <div css={rootCss}>
          {content.map(item => {
            const { id, data, componentName } = item

            const contextProps = {}

            if (componentName === `CloudFeature`) {
              if (featureNum % 2 === 0) {
                contextProps.reversed = true
              }

              featureNum += 1
            }

            return (
              <ContentItemRenderer
                key={id}
                data={data}
                componentName={componentName}
                {...contextProps}
              />
            )
          })}
        </div>
      </ComponentsProvider>
    </CustomPageLayout>
  )
}
