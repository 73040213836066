import React from "react"
import { contentPositionerCss } from "../../../shared/styles"
import { ContentItemRenderer } from "../../../CustomPageLayout"
import {
  displayOnDesktopOnly,
  displayOnMobileOnly,
} from "../../../shared/styles"
import { sectionGradientHeadingCss } from "../styles"
import withData from "./withData"

const HeightAdjustment = {
  incrementalBuilds: `-6% 0 -12%`,
  cmsPreviews: `-6% 0 -15%`,
}

const rootCss = theme => [contentPositionerCss({ theme })]

const innerCss = ({ theme, reversed = false }) => [
  {
    alignItems: `center`,
    display: `grid`,
    gridColumnGap: theme.space[9],
    padding: `${theme.space[5]} ${theme.space[2]}`,

    [theme.mediaQueries.desktop]: {
      gridTemplateColumns: `1.3fr 2fr`,
      padding: `${theme.space[5]} ${theme.space[9]}`,
    },
  },
  reversed && {
    "& > div:nth-child(1)": {
      order: 1,
    },
    "& > div:nth-child(2)": {
      order: 2,
    },

    [theme.mediaQueries.desktop]: {
      gridTemplateColumns: `2fr 1.3fr`,
      "& > div:nth-child(1)": {
        order: 2,
      },
      "& > div:nth-child(2)": {
        order: 1,
      },
    },
  },
]

const infoCss = theme => ({
  alignItems: `center`,
  display: `flex`,
  flexDirection: `column`,
  textAlign: `center`,

  [theme.mediaQueries.desktop]: {
    alignItems: `flex-start`,
    textAlign: `left`,
  },
})

const lightHeadingCss = theme => ({
  color: theme.colors.blue[50],
  fontSize: theme.fontSizes[2],
  fontWeight: theme.fontWeights.bold,
  letterSpacing: theme.letterSpacings.tracked,
  marginTop: theme.space[4],
  marginBottom: theme.space[3],

  [theme.mediaQueries.desktop]: {
    fontSize: theme.fontSizes[3],
  },
})

const descriptionCss = theme => ({
  color: theme.colors.grey[60],
  marginTop: theme.space[8],
  marginBottom: theme.space[7],

  [theme.mediaQueries.desktop]: {
    marginBottom: 0,
  },
})

const displayCss = _theme => ({
  alignItems: `center`,
  display: `flex`,
  justifyContent: `center`,
})

const illuCss = ({ theme, heightAdjustment = `0` }) => [
  {
    width: `115%`,
    height: `auto`,
    maxWidth: `none`,

    // this ~roughly nullyfy the image height increased by shadows
    // the vertical margin values are manually chosen
    margin: heightAdjustment,

    [theme.mediaQueries.desktop]: {
      width: `100%`,
    },
  },
]

const iconCss = theme => ({
  alignItems: `center`,
  background: theme.colors.blue[10],
  borderRadius: theme.radii[6],
  display: `flex`,
  height: theme.space[8],
  justifyContent: `center`,
  width: theme.space[8],

  svg: { fill: theme.colors.blue[60], height: theme.space[6], width: `auto` },
})

const contextStyles = {
  Markdown: descriptionCss,
  Svg: iconCss,
  shortHeading: lightHeadingCss,
  longHeading: sectionGradientHeadingCss,
}

const contextProps = {
  shortHeading: {
    as: `h3`,
    variant: `LIGHT`,
  },
  longHeading: {
    as: `h4`,
    variant: `EMPHASIZED`,
  },
}

export function CloudFeature({ content, pictures, reversed = false }) {
  return (
    <React.Fragment>
      <article css={rootCss}>
        <div css={theme => innerCss({ theme, reversed })}>
          <div css={infoCss}>
            {content.map((item, idx) => {
              const { id, data, componentName } = item

              let identyfier = componentName

              if (componentName === `Heading`) {
                identyfier =
                  content[idx - 1] &&
                  content[idx - 1].componentName === `Heading`
                    ? `longHeading`
                    : `shortHeading`
              }

              return (
                <ContentItemRenderer
                  key={id}
                  data={data}
                  componentName={componentName}
                  css={contextStyles[identyfier]}
                  {...contextProps[identyfier]}
                />
              )
            })}
          </div>
          <div css={displayCss}>
            {pictures.map(pic => {
              const { componentName, id, data } = pic

              const viewportIdentifier = data?.identifier.match(
                /mobile|desktop/g
              )[0]

              const mobileVersion =
                viewportIdentifier === `mobile` ? true : false

              const featureIdentifier = data?.identifier.match(/(^\w+)_/)
              const heightAdjustment =
                featureIdentifier &&
                featureIdentifier[1] &&
                HeightAdjustment[featureIdentifier[1]]

              return (
                <ContentItemRenderer
                  key={id}
                  data={data}
                  componentName={componentName}
                  asImg={true}
                  css={theme => [
                    illuCss({ theme, heightAdjustment }),
                    mobileVersion
                      ? displayOnMobileOnly({ theme })
                      : displayOnDesktopOnly({ theme }),
                  ]}
                />
              )
            })}
          </div>
        </div>
      </article>
    </React.Fragment>
  )
}

export default withData(CloudFeature)
