import * as React from "react"
import withData from "./Logos.withData"
import { ContentRenderer } from "../../../CustomPageLayout"

const logosCss = _theme => ({
  alignItems: `center`,
  display: `flex`,
  flexWrap: `wrap`,
  justifyContent: `center`,
  width: `100%`,
})

const logoCss = theme => ({
  margin: `${theme.space[3]} ${theme.space[6]}`,
})

const contextStyles = {
  Svg: logoCss,
}

const contextProps = {
  Svg: {
    asImg: true,
  },
}

export function Logos({ content }) {
  return (
    <div css={logosCss}>
      <ContentRenderer
        content={content}
        contextProps={contextProps}
        contextStyles={contextStyles}
      />
    </div>
  )
}

export default withData(Logos)
