import React from "react"
import { contentPositionerCss } from "../../../shared/styles"
import { gradientHeadingCss } from "../styles"
import withData from "./withData"
import { ContentRenderer } from "../../../CustomPageLayout"

const rootCss = theme => [
  contentPositionerCss({ theme }),
  {
    alignItems: `center`,
    display: `flex`,
    flexDirection: `column`,
    textAlign: `center`,
  },
]

const headingCss = theme => [
  gradientHeadingCss({ theme, gradientDirection: 180 }),
  {
    fontSize: theme.fontSizes[6],

    span: {
      display: `block`,
      WebkitTextFillColor: theme.colors.grey[90],
    },

    [theme.mediaQueries.desktop]: {
      fontSize: theme.fontSizes[7],
    },
  },
]

const ledeCss = theme => ({
  color: theme.colors.grey[70],
  marginTop: theme.space[6],
  maxWidth: `40rem`,
  margin: `${theme.space[7]} auto`,
})

const contextStyles = {
  Heading: headingCss,
  Markdown: ledeCss,
}

const contextProps = {
  Heading: {
    as: `h2`,
    variant: `EMPHASIZED`,
  },
}

export function DeploymentOptions({ content }) {
  return (
    <div css={rootCss}>
      <ContentRenderer
        content={content}
        contextStyles={contextStyles}
        contextProps={contextProps}
      />
    </div>
  )
}

export default withData(DeploymentOptions)
