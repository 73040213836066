import { default as PageHeader } from "./PageHeader"
import { default as Guarantee } from "./Guarantee"
import { default as DeploymentOptions } from "./DeploymentOptions"
import { default as Logos } from "./DeploymentOptions/Logos"
import { default as CloudFeature } from "./CloudFeature"
import { default as CmsMonograms } from "./CloudFeature/CmsMonograms"
import { default as WIBProof } from "./WIBProof"
import { GetStarted } from "./GetStarted"

const all = {
  PageHeader,
  Guarantee,
  GetStarted,
  DeploymentOptions,
  Logos,
  CloudFeature,
  CmsMonograms,
  WIBProof,
}

export {
  PageHeader,
  Guarantee,
  GetStarted,
  DeploymentOptions,
  Logos,
  CloudFeature,
  CmsMonograms,
  WIBProof,
  all,
}
