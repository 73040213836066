import React from "react"

import {
  LazyContentEditorHelper,
  normalizeContent,
  normalizeCmsMeta,
} from "../../../CustomPageLayout"
import { editorHelperActive } from "../../../shared/env/constants"

const withData = Target => ({ data = {}, ...rest }) => {
  const rawContent = normalizeContent(data.content)

  const structuredContent = rawContent.reduce((acc, cur) => {
    if (cur.componentName === `Cta`) {
      const previous = acc[acc.length - 1]

      if (previous?.groupOf === `cta`) {
        previous.items.push(cur)
      } else {
        acc.push({ groupOf: `cta`, items: [cur] })
      }
    } else {
      acc.push(cur)
    }

    return acc
  }, [])

  const result = {
    content: structuredContent,
  }

  const cms = normalizeCmsMeta(data)

  return editorHelperActive ? (
    <LazyContentEditorHelper cms={cms}>
      <Target {...result} {...rest} />
    </LazyContentEditorHelper>
  ) : (
    <Target {...result} {...rest} />
  )
}

export default withData
