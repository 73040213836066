import React from "react"
import { textLinearGradient } from "../styles"
import { contentPositionerCss } from "../../../shared/styles"
import { ContentRenderer } from "../../../CustomPageLayout"
import withData from "./withData"

const rootCss = theme => [contentPositionerCss({ theme })]

const headingCss = theme => ({
  fontSize: theme.fontSizes[8],
  letterSpacing: theme.letterSpacings.tight,
  lineHeight: 1.1,
  textAlign: `center`,

  span: [
    {
      display: `block`,
    },
    textLinearGradient({ theme }),
  ],

  [theme.mediaQueries.desktop]: {
    fontSize: theme.fontSizes[11],
  },
})

const contextStyles = {
  Heading: headingCss,
}

const contextProps = {
  Heading: {
    as: `h2`,
    variant: `EMPHASIZED`,
  },
}

export function Guarantee({ content }) {
  return (
    <div css={rootCss}>
      <ContentRenderer
        content={content}
        contextStyles={contextStyles}
        contextProps={contextProps}
      />
    </div>
  )
}

export default withData(Guarantee)
