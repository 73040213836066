import React from "react"
import { contentPositionerCss, ctaBtnCss } from "../../../shared/styles"
import { GatsbyCloudLogo } from "../../../shared/logos/GatsbyCloudLogo"
import { gradientHeadingCss } from "../styles"
import { ContentItemRenderer } from "../../../CustomPageLayout"
import withData from "./withData"

import leftBackground from "../../../../assets/backgrounds/cloud-page_header-dots-a.svg"
import rightBackground from "../../../../assets/backgrounds/cloud-page_header-dots-b.svg"
import bottomBackground from "../../../../assets/backgrounds/cloud-page_header-dots-c.svg"

const rootCss = theme => ({
  background: `url(${bottomBackground}) repeat-x center bottom`,
  paddingBottom: theme.space[10],

  [theme.mediaQueries.desktop]: {
    background: `url(${leftBackground}) no-repeat left bottom, url(${rightBackground}) no-repeat right center`,
  },
})

const headerCss = theme => [
  contentPositionerCss({ theme }),
  {
    alignItems: `center`,
    display: `flex`,
    flexDirection: `column`,
    justifyContent: `center`,
    letterSpacing: theme.letterSpacings.tight,
    textAlign: `center`,
  },
]

const headingCss = theme => [
  gradientHeadingCss({ theme }),
  {
    fontSize: theme.fontSizes[10],

    [theme.mediaQueries.tablet]: {
      span: {
        display: `block`,
      },
    },

    [theme.mediaQueries.desktop]: {
      fontSize: theme.fontSizes[15],
    },
  },
]

const logoCss = theme => ({
  height: theme.space[13],
  marginBottom: theme.space[7],
  width: theme.space[13],
})

const ledeCss = theme => ({
  color: theme.colors.grey[70],
  fontSize: theme.fontSizes[2],
  margin: 0,
  marginTop: theme.space[9],
  maxWidth: `40rem`,

  span: {
    color: theme.colors.blue[60],
    fontWeight: theme.fontWeights.bold,
  },

  [theme.mediaQueries.desktop]: {
    fontSize: theme.fontSizes[4],
  },
})

const actionsCss = theme => ({
  display: `flex`,
  flexDirection: `column`,
  marginTop: theme.space[9],

  "& > :first-child": {
    marginBottom: theme.space[4],
  },

  [theme.mediaQueries.phablet]: {
    flexDirection: `row`,

    "& > :first-child": {
      marginBottom: 0,
      marginRight: theme.space[4],
    },
  },
})

const contextStyles = {
  Heading: headingCss,
  Markdown: ledeCss,
}

const contextProps = {
  Heading: {
    as: `h2`,
    variant: `EMPHASIZED`,
  },
}

export function PageHeader({ content }) {
  return (
    <div css={rootCss}>
      <header css={headerCss}>
        <GatsbyCloudLogo css={logoCss} />
        {content.map(item => {
          const { id, componentName, data, groupOf, items } = item

          if (groupOf === `cta`) {
            return (
              <div key="actions" css={actionsCss}>
                {items.map((cta, idx) => {
                  const { id, componentName, data } = cta
                  return (
                    <ContentItemRenderer
                      key={id}
                      data={data}
                      componentName={componentName}
                      css={theme =>
                        ctaBtnCss({
                          theme,
                          tone: `cloud`,
                          variant: idx > 0 && `secondary`,
                        })
                      }
                    />
                  )
                })}
              </div>
            )
          } else {
            return (
              <ContentItemRenderer
                key={id}
                data={data}
                componentName={componentName}
                css={contextStyles[componentName]}
                {...contextProps[componentName]}
              />
            )
          }
        })}
      </header>
    </div>
  )
}

export default withData(PageHeader)
