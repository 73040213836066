import * as React from "react"
import withData from "./CmsMonograms.withData"
import { ContentItemRenderer } from "../../../CustomPageLayout"

const logosCss = theme => ({
  listStyle: `none`,
  display: `flex`,
  flexWrap: `wrap`,
  justifyContent: `center`,
  margin: 0,
  marginTop: theme.space[6],
  marginBottom: theme.space[8],

  li: {
    margin: `0 ${theme.space[4]} ${theme.space[5]}`,

    img: {
      width: theme.space[5],
      height: theme.space[5],
    },
  },

  [theme.mediaQueries.desktop]: {
    marginBottom: 0,
    justifyContent: `flex-start`,

    li: {
      margin: `0 ${theme.space[6]} ${theme.space[5]} 0`,
    },
  },
})

export function CmsMonograms({ content }) {
  return (
    <ul css={logosCss}>
      {content.map(item => {
        const { id, data, componentName } = item

        return (
          <li key={id}>
            <ContentItemRenderer
              data={data}
              componentName={componentName}
              asImg={true}
            />
          </li>
        )
      })}
    </ul>
  )
}

export default withData(CmsMonograms)
