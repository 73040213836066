import React from "react"
import { Heading, LinkButton } from "gatsby-interface"
import { gradientHeadingCss } from "../styles"
import {
  contentPositionerCss,
  ctaBtnCss,
  sectionNumberCss,
} from "../../../shared/styles"
import { GitPlatforms } from "../../../shared/logos/GitPlatforms"
import { GatsbyCloudLogo } from "../../../shared/logos/GatsbyCloudLogo"
import { Ornament } from "./Ornament.js"

const rootCss = theme => [
  contentPositionerCss({ theme }),
  {
    paddingLeft: 0,
    paddingRight: 0,

    [theme.mediaQueries.desktop]: {
      paddingLeft: theme.space[8],
      paddingRight: theme.space[8],
    },
  },
]

const innerCss = theme => [
  {
    alignItems: `center`,
    background: `linear-gradient(0deg, ${theme.colors.purple[10]}, ${theme.colors.blue[5]} 80%)`,
    display: `flex`,
    flexDirection: `column`,
    padding: `${theme.space[11]} ${theme.space[5]} ${theme.space[13]}`,
    textAlign: `center`,
    position: `relative`,
    zIndex: 0,

    [theme.mediaQueries.desktop]: {
      padding: `${theme.space[13]} ${theme.space[10]} ${theme.space[15]}`,
      borderRadius: theme.radii[4],
    },
  },
]

const headingCss = theme => ({
  ...gradientHeadingCss({ theme, gradientDirection: 180 }),
  fontSize: theme.fontSizes[5],
  letterSpacing: theme.letterSpacings.tight,
  textAlign: `center`,

  [theme.mediaQueries.tablet]: {
    fontSize: theme.fontSizes[6],
  },
})

const shoutOutCss = theme => ({
  ...gradientHeadingCss({ theme, gradientDirection: 180 }),
  margin: 0,
  fontWeight: theme.fontWeights.extraBold,
  fontFamily: theme.fonts.heading,
  fontSize: theme.fontSizes[8],
  marginTop: `-${theme.space[5]}`,

  [theme.mediaQueries.desktop]: {
    fontSize: theme.fontSizes[15],
  },
})

const numberCss = theme => [
  sectionNumberCss({ theme, color: `blue` }),
  {
    marginTop: theme.space[10],
    marginBottom: theme.space[6],
  },
]

const logoCss = theme => ({
  height: theme.space[15],
  width: theme.space[15],
  marginBottom: theme.space[5],
})

const logosCss = theme => ({
  marginTop: theme.space[7],
  maxWidth: `50%`,
})

const noteCss = theme => ({
  color: theme.colors.grey[50],
  fontSize: theme.fontSizes[1],
  margin: 0,
  marginTop: theme.space[3],
})

const ornamentCss = _theme => ({
  position: `absolute`,
  right: 0,
  top: 0,
  transform: `translateY(-30%)`,
})

export function GetStarted() {
  return (
    <div css={rootCss}>
      <div css={innerCss}>
        <GatsbyCloudLogo css={logoCss} />
        <Heading as="h2" css={headingCss} variant="EMPHASIZED">
          Ready to <br />
          get started?
        </Heading>
        <span css={numberCss}>1</span>
        <LinkButton
          to="/dashboard/signup/"
          css={theme => ctaBtnCss({ theme, tone: `cloud` })}
          size="XL"
        >
          Sign up free
        </LinkButton>
        <span css={numberCss}>2</span>
        <Heading as="span" css={headingCss} variant="EMPHASIZED">
          Connect <br />
          your repositories
        </Heading>
        <GitPlatforms css={logosCss} />
        <p css={noteCss}>Bitbucket support coming soon</p>
        <span css={numberCss}>3</span>
        <p css={shoutOutCss}>
          Collaborate. Build. <br />
          Deploy. Repeat.
        </p>
        <Ornament css={ornamentCss} />
      </div>
    </div>
  )
}
