import { graphql } from "gatsby"
import { CloudPage } from "../../components/CloudPage"
import { withData } from "../../components/CustomPageLayout"

function CustomPageCloudTemplate(props) {
  return withData(props)(CloudPage)
}

export default CustomPageCloudTemplate

export const pageQuery = graphql`
  query CloudTemplateQuery($id: String!) {
    contentfulCustomPage(id: { eq: $id }) {
      ...CustomPage
    }
  }
`
