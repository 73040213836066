export const textLinearGradient = ({ theme, direction = 0 }) => ({
  background: `linear-gradient(${direction}deg,${theme.colors.blue[60]},  ${theme.colors.purple[60]})`,
  WebkitBackgroundClip: `text`,
  WebkitTextFillColor: `transparent`,
  paddingBottom: `0.1em`, // with condensed line-height like 1.1 text clip cuts the bottom edges
  marginBottom: `-0.1em`, // of letters like "p" or "g", this temporary hack prevents that , but that needs more investigation
})

export const gradientHeadingCss = ({ theme, gradientDirection = 0 }) => ({
  ...textLinearGradient({ theme, direction: gradientDirection }),
  color: theme.colors.blue[70],
  fontSize: theme.fontSizes[15],
  letterSpacing: theme.letterSpacings.tight,
  lineHeight: 1.1, // to-do add 1.1 value to theme
})

export const sectionGradientHeadingCss = theme => ({
  ...gradientHeadingCss({ theme, gradientDirection: 180 }),
  fontSize: theme.fontSizes[8],
})
